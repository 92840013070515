import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { AccountProvider } from "./Context/AccountContext";
import { AuthProvider } from "./Context/AuthContext";
import { BridgeTransferProvider } from "./Context/BridgeTransferContext";
import { BudgetProvider } from "./Context/BudgetContext";
import { ExpenseProvider } from "./Context/ExpenseContext";
import { TransactionProvider } from "./Context/TransactionContext";
import Routers from "./Routes";
import { CustomToaster } from "./Utils";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <TransactionProvider>
          <AccountProvider>
            <BridgeTransferProvider>
              <BudgetProvider>
                <ExpenseProvider>
                  <Routers />
                </ExpenseProvider>
              </BudgetProvider>
            </BridgeTransferProvider>
          </AccountProvider>
        </TransactionProvider>
        <CustomToaster />
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Eye, EyeOff, Key, Mail } from "react-feather";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { useAuth } from "../../../Context/AuthContext";

const LoginContainer = () => {
  const [show, setShow] = useState(false);
  const [loginSpine, SetLogInSpine] = useState(false);
  const toggleShow = () => setShow(!show);
  const { LoginSchema, handleLogin } = useAuth();
  const {register,handleSubmit,formState: { errors },} = useForm({ resolver: yupResolver(LoginSchema) });
  const navigate = useNavigate();

  const onSubmit = (data) => handleLogin({ data,SetLogInSpine });

  const handleForgotPassword = ()=>{
    navigate(`${process.env.PUBLIC_URL}/forgot_password`,{ state: { forgotToken : true}})
  }

  return (
    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <h2 class="text-center mb-0">Sign in to account</h2>
        <p class="text-center">Enter your email &amp; password to login</p>
        <FormGroup className="form-group">
          <Label>Email</Label>
          <input className="form-control" {...register("email")} type="email" placeholder="Enter Email" />
          <Mail />
          {errors.email && <span className="text-danger fs-6">{errors.email.message}</span>}
        </FormGroup>
        <FormGroup className="form-group">
          <Label>Password</Label>
          <input className="form-control" {...register("password")} type={show ? "text" : "password"} placeholder="Enter Password" />
          <Key />
          <div className={`toggle-password ${show ? "show" : ""}`} onClick={toggleShow}>
            {show ? <Eye />: <EyeOff/>}
          </div>
          {errors.password && <span className="text-danger fs-6">{errors.password.message}</span>}
        </FormGroup>
        <FormGroup>
          <div className="forgot-pass">
            <Button color="transparent"  className="bg-transparent border-0 p-0" tag="a" onClick={handleForgotPassword}>{"Forget Password ?"}</Button>
          </div>
        </FormGroup>
        <FormGroup className="mb-0 loader-btn">
          <Button disabled={loginSpine ? true : false} type="submit" color="" className="btn-solid">Login {loginSpine && <div className="loader"></div>}</Button>
        </FormGroup>
      {/* <div className="auth-content">
      </div> */}
    </Form>
  );
};

export default LoginContainer;

import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Href } from "../../Constants";

const MessageTabs = ({ handleMessage, messageType }) => {
  const [basicTab, setBasicTab] = useState(messageType);
  return (
    <Nav pills>
      <NavItem>
        <NavLink
          href={Href}
          className={basicTab === "send" ? "active" : ""}
          onClick={() => {
            setBasicTab("send");
            handleMessage("send");
          }}
        >
          {"Send Messages"}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href={Href}
          className={basicTab === "receive" ? "active" : ""}
          onClick={() => {
            setBasicTab("receive");
            handleMessage("receive");
          }}
        >
          {"Received Messages"}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href={Href}
          className={basicTab === "all" ? "active" : ""}
          onClick={() => {
            setBasicTab("all");
            handleMessage(null);
          }}
        >
          {"All Messages"}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default MessageTabs;

import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useAuth } from "../Context/AuthContext";
import { useHeaderScroll } from "../Hooks/useHeaderScroll";
import HeaderNotifications from "./HeaderNotifications";
import Sidebar from "./Sidebar/Sidebar";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const navigate = useNavigate();
  const upScroll = useHeaderScroll(false);
  const { logout } = useAuth();
  const [toggle, setToggle] = useState(false);
  const handleDarkLight = () => {
    setToggle(!toggle);
    document.body.classList.toggle("dark-only");
  };
  const handleFullscreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className={`page-wrapper`}>
      <div className={`page-body-wrapper`}>
        <div className={`page-header ${isSidebarOpen ? "close_icon" : ""}`}>
          {" "}
          <Container fluid className={`${upScroll ? "sticky" : ""} main-navbar d-flex justify-content-between container-fluid p-0`}>
            <div className="header-search">
              <input type="text" className="search-bar" placeholder="Search....." />
            </div>
            <ul className={`common-flex right-navbar`}>
              <li onClick={handleFullscreen} className={`light-box ${toggle ? "active" : ""}`}>
                {!isFullScreen ? <i class="fa-solid fa-compress"></i> : <i class="fa-solid fa-expand"></i>}
              </li>
              <li onClick={handleDarkLight} className={`light-box ${toggle ? "active" : ""}`}>
                {toggle ? <img className="img-dark" src={`../assets/svg/moon.svg`} alt="" /> : <img src={`../assets/svg/sun.svg`} alt="" />}
              </li>

              <HeaderNotifications />
              <li>
                <Button className="signout" color="dark" onClick={() => logout(navigate)}>
                  Sign Out
                </Button>
              </li>
            </ul>
          </Container>
        </div>

        <div className="page-body">
          <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

          <div className="page-main-body">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;

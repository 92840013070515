import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../Context/AuthContext";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const checkTokenAndNavigate = () => {
  const token = localStorage.getItem("finance_token");
  if (!token) {
    return false; // Token is missing, return false or handle as needed
  }
  return true; // Token is present
};

// Middleware to set Bearer token from local storage and handle navigation
axiosInstance.interceptors.request.use(
  (config) => {
    const tokenExists = checkTokenAndNavigate();
    if (tokenExists) {
      const token = localStorage.getItem("finance_token");
      const authToken = JSON.parse(token);
      config.headers["x-access-token"] = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

export const useErrorMessageHandler = () => {
  const { logout } = useAuth();
  const handleError = (error, navigate) => {
    // Check if error status is 401 (unauthorized) or 403 (forbidden) and handle sign-out
    if (error.response?.status === 401 || error.response?.status === 403) {
      // logout(navigate); // Perform sign-out
      navigate && toast.error("Session expired. Please log in again.");
      return logout(navigate); // Redirect user to login page
    } else {
      // logout(navigate); // Perform sign-out
      // For other errors, show a general error message
      // navigate("/login"); // Redirect user to login page
      return toast.error(error?.response?.data?.message || error?.response?.data?.error || "An error occurred.");
    }
  };

  return { handleError };
};

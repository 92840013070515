import { MenuList } from "../../Data/Layout/SidebarData";
import Menulist from "./Menulist";

const SidebarMenuList = ({activeMenu,setActiveMenu}) => {
  return (
    <ul>
      <Menulist menu={MenuList} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
    </ul>
  );
};

export default SidebarMenuList;

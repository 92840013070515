import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { budgetChartApi, budgetStatisticsApi, getStatisticsApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import InnerLoader from "../../Layout/InnerLoader";
import BankPaymentsChart from "./LineChart/BankPaymentChart";
import CreditCardChart from "./LineChart/CreditCardChart";
import EthereumChart from "./LineChart/EthereumChart";
import TreasuryBalance from "./LineChart/TreasuryBalance";
import RecentBank from "./RecentTransfers/RecentBank";
import RecentCredit from "./RecentTransfers/RecentCredit";
import RecentEthereum from "./RecentTransfers/RecentEthereum";
import RecentTreasury from "./RecentTransfers/RecentTreasury";
import BankPaymentsStatistics from "./TopRecipientsCharts/BankPaymentStatistics";
import CreditCardStatistics from "./TopRecipientsCharts/CreditCardStatistics";
import EthereumCardStatistics from "./TopRecipientsCharts/EthereumCardStatistics";
import TopTreasury from "./TopRecipientsCharts/TopTreasury";

const DashboardContainer = () => {
  const navigate = useNavigate();
  const { isPending: pendingGetStatics, data: staticsData, refetch: statisticsRefetch } = useGetApi({ url: getStatisticsApi, options: { enabled: false, refetchOnWindowFocus: false }, navigate: navigate });
  const { isPending: pendingBudgetGetStatics, data: budgetStaticsData, refetch: refetchBudgetStatistics } = useGetApi({ url: budgetStatisticsApi, options: { enabled: false, refetchOnWindowFocus: false } });
  const { isPending: pendingChartData, data: chartData, refetch: refetchBudgetChart } = useGetApi({ url: budgetChartApi, options: { enabled: false, refetchOnWindowFocus: false } });

  useEffect(() => {
    statisticsRefetch()
      .then(() => refetchBudgetStatistics())
      .then(() => refetchBudgetChart())
      .catch((err) => {
        console.error(err);
      });
    // Fetch data here
  }, []);
  if (pendingGetStatics || pendingBudgetGetStatics || pendingChartData) {
    return <InnerLoader />;
  }

  return (
    <Container fluid className="dashboard-section">
      <Row className="g-4 treasury-stats">
        {/* <TopBankTransfer />  */}
        <TopTreasury staticsData={staticsData} />
        <TreasuryBalance />
        <EthereumCardStatistics staticsData={chartData} />
        <EthereumChart />
        <CreditCardStatistics staticsData={chartData} />
        <CreditCardChart />
        {/* <BankBalance /> */}
        {/* <SmallCard staticsData={staticsData} /> */}
        <BankPaymentsStatistics staticsData={chartData} />
        <BankPaymentsChart />
        {/* <RecentBank />   */}
        <RecentTreasury staticsData={staticsData} />
        <RecentBank staticsData={budgetStaticsData} />
        <RecentEthereum staticsData={budgetStaticsData} />
        <RecentCredit staticsData={budgetStaticsData} />
      </Row>
    </Container>
  );
};
export default DashboardContainer;

import toast, { Toaster } from "react-hot-toast";

// CustomToaster
export const CustomToaster = () => (
  <Toaster
    toastOptions={{
      duration: 3000,
      position: "top-right",
      style: {
        fontWeight: 700,
        minWidth: 200,
      },
      success: {
        style: {
          background: "rgb(229, 245, 227)",
          color: "rgba(var(--success), 1)",
        },
      },
      error: {
        style: {
          background: "rgb(255, 238, 237)",
          color: "rgba(var(--error), 1)",
        },
      },
    }}
  />
);

// Dynamic Image
const images = require.context(`/public/assets/images`, true);

export const dynamicImage = (image) => {
  return images(`./${image}`);
};

// Copy Text
// export const handleCopyCode = (code) => navigator.clipboard.writeText(code);

// Date Formate
export const formatDateTime = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // use 24-hour format
  };

  // Format date and time
  const formattedDate = date == "Invalid Date" ? "--" : date.toLocaleDateString("en-GB", options).replace(/\//g, "-");

  return `${formattedDate}`;
};

//format number
export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num;
};

// thousand separator
export const formatAmount = (amount) => {
  return amount >= 1 ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : amount;
};

// turncate from middle
export const truncateMiddle = (text, maxLength) => {
  if (text.length <= maxLength) return text;

  const halfLength = Math.floor(maxLength / 2);
  return `${text.substring(0, halfLength)}...${text.substring(text.length - halfLength)}`;
};

// copy text
export const handleCopyCode = (id) => {
  navigator.clipboard.writeText(id).then(() => toast.success("Address copied to clipboard"));
};

// convert given time in milliseconds
export const toMilliseconds = (hrs, min, sec) => (hrs * 60 * 60 + min * 60 + sec) * 1000;

export const Capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

export const handleDrop = (event, setFieldValue) => {
  event.preventDefault();
  const droppedFiles = event.dataTransfer.files;
  if (droppedFiles.length > 0) {
    const newFile = droppedFiles[0]; // Only taking the first file
    setFieldValue("data", newFile); // Set the dropped file in Formik's values
  }
  if (droppedFiles.length > 1) {
    return toast.error("Could not drop more than 1 file");
  }
};

// Prevent default behavior on dragover to allow drop
export const handleDragOver = (event) => {
  event.preventDefault();
};

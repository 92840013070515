import React from "react";
import { File } from "react-feather";
import { useBudget } from "../../../Context/BudgetContext";
import { Capitalize } from "../../../Utils";
import CommonTable from "../../Common/CommonTable";

const AvailCaymanPayments = ({ data, isLoadingGetData, setEditMode, handleDelete, handleRemoveAgreement }) => {
  const { toggleImport, setUploadId, handleSort, sortOrder } = useBudget();

  const columns = [
    { label: "Corporate Name", key: "corporate_name", isSortable: true },
    { label: "Brand Name", key: "brand_name", isSortable: true },
    { label: "Agreement", key: "agreeement" },
    { label: "Agreement Date", key: "agreeement_date", type: "date", isSortable: true },
    { label: "Commencement Date", key: "commencement_date", isSortable: true },
    { label: "Tier", key: "tier" },
    { label: "Term", key: "term" },
    { label: "Expiry/Renewal Date", key: "expiry_date", type: "date", isSortable: true },
    {
      label: "Signed Agreement",
      key: "signed_agreement",
      isSortable: true,
      render: (item) =>
        item?.signed_agreement ? (
          <div className="common-flex ">
            <div className="outer-box">
              <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/upload/${item?.signed_agreement}`}>
                <File size={20} />
              </a>
            </div>
            <div className="outer-box">
              <i onClick={() => handleRemoveAgreement(item?.id)} class="fa-solid fa-xmark" />
            </div>
          </div>
        ) : (
          "--"
        ),
    },
    { label: "Status", key: "status", isSortable: true, render: (item) => <span className={`badge ${item?.status === "Paid" || item?.status === "Completed" || item?.status === "Live" ? "badge-light-success" : item?.status === "Expired" || item?.status === "Cancelled" ? "badge-light-danger" : "badge-light-warning  "}`}> {Capitalize(item?.status)} </span> },
  ];

  const handleUpload = (id) => {
    toggleImport();
    setUploadId(id);
  };

  return <CommonTable handleSort={handleSort} sortOrder={sortOrder} serialNo tableClass={"budget-table bvi-table"} columns={columns} data={data?.data || []} uploadFile={handleUpload} isLoading={isLoadingGetData} onEdit={setEditMode} onDelete={handleDelete} />;
};

export default AvailCaymanPayments;

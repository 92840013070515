import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Menulist = ({ activeMenu, setActiveMenu, menu, level = 0 }) => {
  const location = useLocation(); // Correct way to access pathname
  const [parentMenu, setParentMenu] = useState("");

  useEffect(() => {
    setParentMenu("");
    if (location.pathname) {
      menu?.forEach((element) => {
        if (element.children) {
          element.children.forEach((child) => {
            if (child.path === location.pathname.split("/")[1]) {
              setParentMenu(element.title);
            } else if (location.pathname.includes(child.path)) {
              setParentMenu(element.title);
            }
          });
        } else if (element.path === location.pathname.split("/")[1]) {
          setParentMenu(element.title);
        } else {
          let splitPath = location.pathname.split("#");
          if (splitPath[0] === element.path) {
            setParentMenu(element.title);
          }
        }
      });
    }
  }, [location.pathname, menu]);

  return (
    <>
      {menu?.map((item, index) => (
        <li key={index} className="sidebar-main-title">
          <>
            {item.path ? (
              <Link to={item.path} className={`sidebar-link sidebar-title ${parentMenu === item.title ? "active" : ""}`}>
                <div className="d-flex align-items-center">
                  {item.icon}
                  <h6>{item.title}</h6>
                </div>
              </Link>
            ) : (
              <a
                href="#!"
                className={`sidebar-link sidebar-title ${parentMenu === item.title ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  setParentMenu((prev) => (prev !== item.title ? item.title : ""));
                }}
              >
                <div className="d-flex align-items-center">
                  {item?.icon}
                  <h6 className={item.lanClass && item.lanClass}>{item.title}</h6>
               
                  {item.children && (parentMenu === item.title ? <i className="fa fa-angle-down" />: <i className="fa fa-angle-right" />)}
               
                </div>
              </a>
            )}

            {item.children && (
              <ul className={`sidebar-submenu ${parentMenu === item.title ? "d-block" : "d-none"}`}>
                <Menulist menu={item.children} level={level + 1} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
              </ul>
            )}
          </>
        </li>
      ))}
    </>
  );
};

export default Menulist;

import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { forgotPasswordApi, loginApi, resetPasswordApi } from "../Api/ApiList";
import { decrypt, encrypt } from "../Api/EncryptDecryptFunction";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check if a token exists in local storage
    return localStorage.getItem("finance_token") !== null;
  });

  // Login
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is a required"),
    password: Yup.string().required("Password is a required"),
  });

  const handleLogin = async ({ data, SetLogInSpine }) => {
    SetLogInSpine(true);
    try {
      const encrypted = encrypt(data);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${loginApi}`, encrypted);
      if (response.status === 200) {
        const loginResponse = decrypt(response?.data?.encryptedData, response?.data?.nonce);
        toast.success(loginResponse?.message);
        const { token } = loginResponse?.data;
        localStorage.setItem("finance_token", JSON.stringify(token));
        setIsAuthenticated(true);
      }
      SetLogInSpine(false);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      SetLogInSpine(false);
    }
  };

  // Forgot Password Code
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is a required"),
  });

  const handleReset = async ({ data, navigate, SetForgotSpin }) => {
    SetForgotSpin(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${forgotPasswordApi}`, { email: data.email });
      if (response.status === 200) {
        toast.success(response.data.message);
        localStorage.setItem("e-uat", JSON.stringify(data.email));
        navigate("/login");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      SetForgotSpin(false);
    }
  };

  // Rest Password Code
  const resetPassSchema = Yup.object().shape({
    password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleResetPassword = async ({ email, token, data, navigate, SetUpdateSpin }) => {
    SetUpdateSpin(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${resetPasswordApi}`, { email: email, token: token, password: data.password });
      if (response.status === 200) {
        toast.success(response.data.message);
        navigate(`${process.env.PUBLIC_URL}/login`);
        localStorage.removeItem("e-uat");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      SetUpdateSpin(false);
    }
  };

  const logout = (navigate) => {
    localStorage.clear();
    setIsAuthenticated(false);
    navigate && navigate("/login");
  };
  const [refreshState, setRefreshState] = useState({
    expenses: {
      isRefreshDisabled: false,
      lastRefreshTime: null,
    },
    transactions: {
      isRefreshDisabled: false,
      lastRefreshTime: null,
    },
  });

  return <AuthContext.Provider value={{ isAuthenticated, logout, refreshState, setRefreshState, LoginSchema, forgotPasswordSchema, handleLogin, handleReset, resetPassSchema, handleResetPassword }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

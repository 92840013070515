export const MenuList = [
  {
    id: 1,
    path: "/transaction",
    type: "link",
    active: true,
    title: "Transactions",
    icon: <i className="fa-solid fa-money-bill-transfer" />,
  },
  {
    id: 2,
    path: "/account",
    type: "link",
    active: false,
    title: "Account",
    icon: <i className="fa-solid fa-user" />,
  },
  {
    id: 3,
    type: "sub",
    // path:"/budget?type=bank_payment    s",
    active: false,
    title: "Budgeting",
    icon: <i className="fa-solid fa-coins" />,
    children: [
      {
        id: 31,
        path: "/budget/bank_payments",
        type: "link",
        active: false,
        title: "Bank Payments",
      },
      {
        id: 32,
        path: "/budget/credit_card_payments",
        type: "link",
        active: false,
        title: "Credit Card Payments",
      },
      {
        id: 33,
        path: "/budget/ethereum_wallet",
        type: "link",
        active: false,
        title: "Ethereum Wallet Payments",
      },
      {
        id: 34,
        path: "/budget/avail_wallet",
        type: "link",
        active: false,
        title: "Avail Wallet Payments",
      },
      {
        id: 35,
        path: "/budget/avail_difc",
        type: "link",
        active: false,
        title: "Avail 3rd Party Contract (DIFC)",
      },
      {
        id: 36,
        path: "/budget/avail_cayman",
        type: "link",
        active: false,
        title: "Avail 3rd Party Contract (Cayman)",
      },
      {
        id: 37,
        path: "/budget/avail_bvi",
        type: "link",
        active: false,
        title: "Avail 3rd Party Contract (BVI)",
      },
    ],
  },
  {
    id: 4,
    path: "/statistics",
    type: "link",
    active: false,
    title: "Statistics",
    icon: <i className="fa-solid fa-chart-line" />,
  },
  {
    id: 5,
    path: "/bridge_transfer",
    type: "link",
    active: false,
    title: "Bridge Transfer",
    icon: <i className="fa-solid fa-hand-holding-dollar" />,
  },
  {
    id: 7,
    path: "/peoples",
    type: "link",
    active: false,
    title: "Peoples",
    icon: <i className="fa-solid fa-users-gear" />,
  },
  {
    id: 6,
    type: "sub",
    // path:"/budget?type=bank_payment    s",
    active: false,
    title: "Billing",
    icon: <i className="fa-solid fa-coins" />,
    children: [
      {
        id: 61,
        path: "/billing/payment_history",
        type: "link",
        active: false,
        title: "Payment History",
      },
      {
        id: 62,
        path: "/billing/invoices",
        type: "link",
        active: false,
        title: "Invoice",
      },
    ],
  },

  {
    id: 8,
    path: "/expenses",
    type: "link",
    active: false,
    title: "Expenses",
    icon: <i className="fa-solid fa-file-signature" />,
  },

  {
    id: 9,
    path: "/notifications",
    type: "link",
    active: false,
    title: "Notifications",
    icon: <i className="fa-solid fa-bell" />,
  },
];

import React, { createContext, useContext, useEffect, useState } from "react";
import { getBudgetDataApi } from "../Api/ApiList";
import { useGetApi } from "../Api/useGetApi";

const BudgetContext = createContext();

export const BudgetProvider = ({ children }) => {
  // BudgetSubProvider state
  const [type, setType] = useState(null);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(null);
  const [limit, setLimit] = useState(null);
  const [uploadId, setUploadId] = useState(null);
  const [params, setParams] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortOrder, setSortOrder] = useState(false);
  const [sortColumn, setSortColumn] = useState("id");
  const [deleteAgreementModal, setDeleteAgreementModal] = useState(false); //

  // Dynamically fetch based on type
  const { isLoading, data, refetch } = useGetApi({
    url: `${getBudgetDataApi}`,
    params, // Fallback to 'bank_payments' if 'type' is not found
    options: { refetchOnWindowFocus: false, enabled: !!Object.values(params).filter((value) => !!value)?.length },
  });

  useEffect(() => {
    data && setTotalPages(data?.totalPages);
  }, [data]);

  // BudgetProvider state
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const toggleDelete = () => setDeleteModal((prev) => !prev);
  const toggleAgreementDeleteModal = () => setDeleteAgreementModal((prev) => !prev);

  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => {
    setUploadId(null);
    setImportModal((prev) => !prev);
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  const [editBudgetData, setEditBudgetData] = useState({});

  return (
    <BudgetContext.Provider
      value={{
        isLoading,
        data,
        refetch,
        type,
        setType,
        totalPages,
        page,
        setPage,
        limit,
        setLimit,
        uploadId,
        setUploadId,
        editModal,
        toggleEdit,
        deleteModal,
        toggleDelete,
        importModal,
        toggleImport,
        setEditBudgetData,
        editBudgetData,
        searchTerm,
        setSearchTerm,
        handleSort,
        sortOrder,
        sortColumn,
        setSortColumn,
        setParams,
        toggleAgreementDeleteModal,
        deleteAgreementModal,
      }}
    >
      {children}
    </BudgetContext.Provider>
  );
};

// Custom hook to use Budget context
export const useBudget = () => {
  return useContext(BudgetContext);
};

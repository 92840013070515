import { useState } from "react";
import SidebarMenuList from "./SidebarMenuList";

const Sidebar = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const [activeMenu, setActiveMenu] = useState([]);

  return (
    <div className={`sidebar-wrapper ${isSidebarOpen ? 'close_icon' : ''}`}>
      <div>
        <nav className="sidebar-main">
          <div id="sidebar-menu">
            <ul className="sidebar-links" id="simple-bar">
              <li className="back-btn w-100">
                {/* <img src="/assets/images/logo.png" className="logo" height={40} width={100} alt="" /> */}
                <h2 className="text-white">Logo</h2>
                <div className="mobile-back text-end ">
                  <span className="text-white" onClick={() => setIsSidebarOpen(!isSidebarOpen)}><i class="fa-solid fa-bars"></i></span>
                </div>
              </li>
              <li className="icon-sidebar">
                {/* <img src="/assets/images/logo.png" className="logo" height={40} width={40} alt="" /> */}
                <h2 className="text-white">Logo</h2>
              </li>
              <SidebarMenuList activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
            </ul>
          </div>
        </nav>
      </div>
      {/* <div className="mt-5 logout-btn">
        <Button onClick={() => logout(navigate)}>Log out</Button>
      </div> */}
    </div>
  );
};

export default Sidebar;
